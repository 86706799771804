import "./Footer.scss";

// lp Data
import lpData from "../Data/LPData";

const Footer = () => {
  const sectionData = lpData.Footer;

  return (
    <div className="lp-footer-wrapper">
      <div className="container lp-footer-container">
        <div className="footer-columns">
          <div className="icon-section footer-column">
            <div className="pulp-logo-wrapper">
              <img src={lpData.appIcon} className="logo" alt="" />
              <img src={lpData.appIconText} className="text" alt="" />
            </div>
          </div>
          <div className="corporate-section footer-column">
            <span className="footer-column-title">Company</span>
            <div className="column-links">
              <a
                href={sectionData.corporateSite}
                target="_blank"
                className="underline"
              >
                会社概要
              </a>
            </div>
          </div>
          <div className="service-section footer-column">
            <span className="footer-column-title">Service</span>
            <div className="column-links">
              <a
                href={sectionData.termsOfService}
                target="_blank"
                className="underline"
              >
                利用規約
              </a>
              <a
                href={sectionData.privacyPolicy}
                target="_blank"
                className="underline"
              >
                プライバシーポリシー
              </a>
              <a
                href={sectionData.transactionLaw}
                target="_blank"
                className="underline"
              >
                特定商取引法に基づく表記
              </a>
            </div>
          </div>
          <div className="sns-section footer-column">
            <span className="footer-column-title">Social</span>
            <div className="column-links">
              <a
                href={sectionData.instagram}
                target="_blank"
                className="underline"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
        <div className="copy-right-section">
          <span>© VIVERTA Inc. All rights reserved.</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
